import React, { useState } from 'react'
import { useMeasure } from 'react-use'
import tw, { theme } from 'twin.macro'

import OverviewHeader from '../OverviewHeader'
import CockpitList from '../CockpitList'
import BarStackChart from '../BarStackChart'
import FossilEnergyConsumptionChart from './FossilEnergyConsumptionChart'
import RenewableEnergyRatioChart from './RenewableEnergyRatioChart'
import ChartToggle from '../ChartToggle'
import EnergyEfficiencyAchievedChart from './EnergyEfficiencyAchievedChart'
import InfoTooltip from '../InfoTooltip'
import ExternalLink from '../ExternalLink'
import DownloadCard from '../DownloadCard'
import BarGroupLineChart from '../BarGroupLineChart'
import {
  getEnergyEfficiencyAchievedData,
  getFossilEnergyConsumptionData,
  getHeatingEnergyPerEra,
  getRenewableEnergyRatioData,
  transformWithEra,
  X_PROP,
} from './utils'
import useData, { useHelperData } from '../../data/useData'

const CHART_BOTTOM_OFFSET = 90
const margin = {
  top: 30,
  left: 70,
  right: 40,
  bottom: 50,
}

const accessors = {
  x: (d) => d[X_PROP],
  y: (d) => d.consumption,
  z: (d) => d.location,
}

const getZProp = (toggleZ) => (toggleZ ? 'category' : 'type')

const getTickFormat = (toggleY) => (toggleY ? '' : '~s')
const getTooltipFormat = (toggleY) => (toggleY ? '.1f' : '.3s')

export default function Energy() {
  const { energy, energyEfficiencyAchieved } = useData()
  const { eraBySiteYear, eraByYear } = useHelperData()
  const [c1Ref, { width: c1Width, height: c1Height }] = useMeasure()
  const [c2Ref, { width: c2Width, height: c2Height }] = useMeasure()
  const [toggleZ, setToggleZ] = useState(true)
  const [toggleY, setToggleY] = useState(true)

  return (
    <div tw="flex-1 lg:grid lg:grid-cols-4 lg:overflow-x-auto">
      <div tw="lg:col-span-3 lg:grid lg:grid-cols-2">
        <CockpitList tw="overflow-hidden">
          <div
            css={[
              `height: calc((100vh - ${theme`spacing.20`}) / 2);`,
              tw`flex flex-col`,
            ]}
            ref={c1Ref}
          >
            <OverviewHeader
              title={
                <>
                  Thermal energy consumption per energy reference area (
                  {unit(toggleY)}) not weather adjusted
                </>
              }
            />
            <div tw="flex-1 h-0 relative">
              {c1Height && (
                <>
                  <EraToggle
                    toggle={toggleY}
                    setToggle={setToggleY}
                    tw="z-10"
                  />
                  {toggleY ? (
                    <BarGroupLineChart
                      width={c1Width}
                      height={c1Height - CHART_BOTTOM_OFFSET}
                      margin={margin}
                      data={getHeatingEnergyPerEra(
                        eraBySiteYear,
                        energy,
                        toggleY
                      )}
                      accessors={accessors}
                      unit={unit(toggleY)}
                      xProp={X_PROP}
                      lineYAccessor={(d) => d.weightedAvg}
                      transformChartData={transformWithEra}
                      tooltipFormat={getTooltipFormat(toggleY)}
                      tickFormat={getTickFormat(toggleY)}
                      legendIsHorizontal={true}
                      tw="px-3 pt-4 pb-3"
                    />
                  ) : (
                    <BarStackChart
                      width={c1Width}
                      height={c1Height - CHART_BOTTOM_OFFSET}
                      margin={margin}
                      data={getHeatingEnergyPerEra(
                        eraBySiteYear,
                        energy,
                        toggleY
                      )}
                      accessors={accessors}
                      unit={unit(toggleY)}
                      xProp={X_PROP}
                      tooltipFormat={getTooltipFormat(toggleY)}
                      tickFormat={getTickFormat(toggleY)}
                      legendIsHorizontal={true}
                      tw="px-3 pt-4 pb-3"
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div>
            <OverviewHeader
              title={
                <>
                  Electricity consumption per energy reference area (
                  {unit(toggleY)})
                </>
              }
            />
            {c1Height && (
              <>
                <div tw="relative">
                  <EraToggle
                    toggle={toggleY}
                    setToggle={setToggleY}
                    tw="z-10"
                  />
                </div>
                {toggleY ? (
                  <BarGroupLineChart
                    width={c1Width}
                    height={c1Height - CHART_BOTTOM_OFFSET}
                    margin={margin}
                    data={getHeatingEnergyPerEra(
                      eraBySiteYear,
                      energy,
                      toggleY
                    )}
                    accessors={accessors}
                    unit={unit(toggleY)}
                    xProp={X_PROP}
                    lineYAccessor={(d) => d.weightedAvg}
                    transformChartData={transformWithEra}
                    tooltipFormat={getTooltipFormat(toggleY)}
                    tickFormat={getTickFormat(toggleY)}
                    legendIsHorizontal={true}
                    tw="px-3 pt-4 pb-3"
                  />
                ) : (
                  <BarStackChart
                    width={c1Width}
                    height={c1Height - CHART_BOTTOM_OFFSET}
                    margin={margin}
                    data={getHeatingEnergyPerEra(
                      eraBySiteYear,
                      energy,
                      toggleY
                    )}
                    accessors={accessors}
                    unit={unit(toggleY)}
                    xProp={X_PROP}
                    tooltipFormat={getTooltipFormat(toggleY)}
                    tickFormat={getTickFormat(toggleY)}
                    legendIsHorizontal={true}
                    tw="px-3 pt-4 pb-3"
                  />
                )}
              </>
            )}
          </div>
        </CockpitList>

        <CockpitList tw="overflow-hidden">
          <div
            css={[
              `height: calc((100vh - ${theme`spacing.20`}) / 2);`,
              tw`flex flex-col`,
            ]}
            ref={c2Ref}
          >
            <OverviewHeader
              title={
                <>
                  Fossil energy use per energy reference area (
                  {fossilEnergyUnit(toggleY)}) not weather adjusted
                </>
              }
            />
            <div tw="flex-1 h-0">
              {c2Height && (
                <FossilEnergyConsumptionChart
                  width={c2Width}
                  height={c2Height - 80}
                  data={getFossilEnergyConsumptionData(energy)}
                  unit={fossilEnergyUnit(toggleY)}
                  zProp={getZProp(toggleZ)}
                  xProp={X_PROP}
                  toggle={
                    <div>
                      <EraToggle toggle={toggleY} setToggle={setToggleY} />
                      <ChartToggle
                        selected={toggleZ}
                        onChange={() => setToggleZ((prev) => !prev)}
                        rightLabel="Category"
                        leftLabel="Type"
                        tw="left-48"
                      />
                    </div>
                  }
                  eraByYear={eraByYear}
                  toggleEra={toggleY}
                  tooltipFormat={getTooltipFormat(toggleY)}
                  tickFormat={getTickFormat(toggleY)}
                  tw="px-3 py-2"
                />
              )}
            </div>
          </div>
          <div>
            <OverviewHeader title="Share of renewable energy, heating not weather adjusted (% p.a.)" />
            {c2Height && (
              <RenewableEnergyRatioChart
                width={c2Width}
                height={c2Height / 2 - 60}
                data={getRenewableEnergyRatioData(energy)}
                unit="% p.a."
                xProp={X_PROP}
                tw="px-3 py-2"
              />
            )}
          </div>
          <div>
            <OverviewHeader
              tw="relative"
              title={
                <div>
                  <span>
                    Improvement of energy efficiency by at least 2% (achieved
                    p.a.)
                  </span>
                  <InfoTooltip
                    containerStyles={tw`absolute top-2 right-2`}
                    contentStyles={tw`w-80`}
                    placement="top"
                    isHoverable={false}
                  >
                    This indicator is based on the “Large energy consumers
                    cantonal target agreement” (KZV) required by the Canton of
                    Zurich, in which the ZHAW is required to improve is energy
                    efficiency by at least 2% based on a reference year, and is
                    dependent on several specific calculation methods. The ZHAW
                    has always achieved this target since 2012. Click here for{' '}
                    <ExternalLink href="https://www.zh.ch/de/umwelt-tiere/energie/grossverbraucher.html">
                      more information on the KZV
                    </ExternalLink>
                    .
                  </InfoTooltip>
                </div>
              }
            />
            {c2Height && (
              <EnergyEfficiencyAchievedChart
                width={c2Width}
                height={c2Height / 2 - 100}
                data={getEnergyEfficiencyAchievedData(energyEfficiencyAchieved)}
                unit="achieved p.a"
                tw="px-3 py-1"
              />
            )}
          </div>
        </CockpitList>
      </div>

      <CockpitList tw="bg-grey-6">
        <OverviewHeader title="EXPLORE" />
        <DownloadCard field="energy" title="Energy" />
        <DownloadCard
          field="energy_efficiency_achieved"
          title="Energy Efficiency Achieved"
        />
        <DownloadCard field="buildings" title="Buildings" />
      </CockpitList>
    </div>
  )
}

function fossilEnergyUnit(toggleY) {
  return toggleY ? 'kg CO₂e/m² p.a.' : 'kg CO₂e'
}

function unit(toggleY) {
  return toggleY ? 'kWh/m² p.a.' : 'kWh'
}

function EraToggle({ toggle, setToggle, ...rest }) {
  return (
    <ChartToggle
      selected={toggle}
      onChange={() => setToggle((prev) => !prev)}
      rightLabel="Per ERA"
      leftLabel="Absolute"
      {...rest}
    />
  )
}
