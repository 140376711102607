import React, { useCallback } from 'react'
import { GridColumns } from '@visx/grid'
import { curveLinear } from '@visx/curve'
import { Group } from '@visx/group'
import { scaleLinear, scalePoint, scaleOrdinal } from '@visx/scale'
import { LinePath } from '@visx/shape'
import { format } from 'd3-format'
import { max } from 'd3-array'
import { schemeTableau10 as schemeSet } from 'd3-scale-chromatic'
import 'twin.macro'

import OverviewAxes from '../OverviewAxes'
import OverviewTooltipContent from '../OverviewTooltipContent'
import Legend from '../Legend'
import OverviewChartTitle from '../OverviewChartTitle'
import useSVGTooltip from '../../connectors/useSvgTooltip'

const defaultMargin = {
  top: 5,
  left: 70,
  right: 40,
  bottom: 40,
}

const circleRadius = 4
const heightOffset = 20
const totalKey = 'Total'

const getX = (d) => d.date
const getZ = (d) => d.category

const valueFormatter = format('.1f')

export default function RenewableEnergyRatioChart({
  width,
  height,
  margin = defaultMargin,
  data,
  unit,
  xProp,
  ...rest
}) {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const chartData = Object.values(
    data.reduce((acc, curr) => {
      const { date, category, renewable_ratio, consumption } = curr

      const totalConsumptionByCategoryYear = acc[date]?.[category]?.consumption
        ? acc[date][category].consumption + consumption
        : consumption
      const totalRenewableByCategoryYear = acc[date]?.[category]?.renewable
        ? acc[date][category].renewable + renewable_ratio
        : renewable_ratio

      return {
        ...acc,
        [date]: {
          ...acc[date],
          date,
          [category]: {
            consumption: totalConsumptionByCategoryYear,
            renewable: totalRenewableByCategoryYear,
          },
        },
      }
    }, {})
  )

  const renewablePercentageData = chartData.map((d) =>
    Object.entries(d).reduce((acc, [key, value]) => {
      if (key === xProp) {
        return { ...acc, [key]: value }
      }
      return {
        ...acc,
        [key]: (value.renewable / value.consumption) * 100,
      }
    }, {})
  )

  const xScale = scalePoint({
    domain: renewablePercentageData.map(getX).sort(),
    range: [0, xMax],
  })

  const yValues = renewablePercentageData.reduce(
    (acc, d) => [
      ...acc,
      ...Object.entries(d)
        .filter(([key]) => key !== xProp)
        .reduce((acc, [, value]) => {
          return [...acc, value]
        }, []),
    ],
    []
  )

  const yScale = scaleLinear({
    domain: [0, max(yValues)],
    range: [yMax, 0],
    nice: true,
  })

  const keys = [...new Set(data.map(getZ))]
  const colorScale = scaleOrdinal({
    domain: keys,
    range: schemeSet,
  })

  const style = { width }

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSVGTooltip()

  const handleTooltip = useCallback(
    (e, { label, value }) => {
      handlePointer(e, {
        label,
        value: valueFormatter(value),
        unit,
      })
    },
    [handlePointer, unit]
  )

  return (
    <div style={style} tw="relative" {...rest}>
      <svg width={width} height={height - heightOffset} ref={containerRef}>
        <Group top={margin.top} left={margin.left}>
          <GridColumns
            scale={xScale}
            width={xMax}
            height={yMax}
            tw="fill-current text-grey-5"
          />
          <OverviewAxes
            xScale={xScale}
            yScale={yScale}
            top={yMax}
            leftNumTicks={yScale.ticks().length / 4}
          />
          {keys.map((key, i) => {
            const chartKey = key === totalKey ? 'emission' : key
            return (
              <Group key={`line-${i}`}>
                <LinePath
                  curve={curveLinear}
                  data={renewablePercentageData}
                  x={(d) => xScale(getX(d)) ?? 0}
                  y={(d) => yScale(d[chartKey]) ?? 0}
                  stroke={colorScale(key)}
                  shapeRendering="geometricPrecision"
                />
                {renewablePercentageData.map((d, j) => {
                  return (
                    <circle
                      key={`circle-${i}-${j}`}
                      stroke="white"
                      strokeWidth="2"
                      fill={colorScale(key)}
                      r={circleRadius}
                      cx={xScale(getX(d))}
                      cy={yScale(d[chartKey])}
                      onPointerEnter={(e) =>
                        handleTooltip(e, { label: key, value: d[chartKey] })
                      }
                      onPointerLeave={hideTooltip}
                    />
                  )
                })}
              </Group>
            )
          })}
          <OverviewChartTitle height={yMax} unit={unit} />
        </Group>

        {tooltipOpen && (
          <>
            <TooltipInPortal
              left={tooltipLeft}
              top={tooltipTop}
              style={tooltipStyles}
            >
              <OverviewTooltipContent tooltipData={tooltipData} />
            </TooltipInPortal>
          </>
        )}
      </svg>

      <Legend scale={colorScale} />
    </div>
  )
}
