import React from 'react'
import { GridColumns } from '@visx/grid'
import { Group } from '@visx/group'
import { scalePoint } from '@visx/scale'
import { AxisBottom } from '@visx/axis'
import { theme } from 'twin.macro'

import IoCheckmarkCircleOutline from '../../images/svg/IoCheckmarkCircleOutline.svg'
import IoCloseCircleOutline from '../../images/svg/IoCloseCircleOutline.svg'

const ICON_SIZE = 30
const defaultMargin = {
  top: 0,
  left: 70,
  right: 70,
  bottom: 20,
}

const getX = (d) => d.date
const getY = (d) => d.achieved

export default function EnergyEfficiencyAchievedChart({
  width,
  height,
  margin = defaultMargin,
  data,
  xProp,
  ...rest
}) {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const xScale = scalePoint({
    domain: data.map(getX).sort(),
    range: [0, xMax],
  })

  const style = { width }

  return (
    <div style={style} tw="relative" {...rest}>
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridColumns
            scale={xScale}
            width={xMax}
            height={yMax}
            tw="fill-current text-grey-5"
          />
          <AxisBottom
            hideZero
            top={yMax}
            scale={xScale}
            strokeWidth="0"
            tickLabelProps={() => ({
              fill: theme`colors.grey.2`,
              fontSize: 12,
              textAnchor: 'middle',
            })}
            tickStroke={theme`colors.grey.5`}
          />
          {data.map((d, i) => {
            return (
              <Group
                key={`energy-efficiency-${getX(d)}`}
                left={xScale(getX(d)) - ICON_SIZE / 2}
                top={yMax / 2 - ICON_SIZE / 2}
              >
                {getY(d) ? (
                  <IoCheckmarkCircleOutline
                    tw="text-green"
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                  />
                ) : (
                  <IoCloseCircleOutline
                    tw="text-red"
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                  />
                )}
              </Group>
            )
          })}
        </Group>
      </svg>
    </div>
  )
}
