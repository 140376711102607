import React from 'react'

import Layout from '../../components/Layout'
import Energy from '../../components/Energy'

export default function EnergyPage() {
  return (
    <Layout>
      <Energy />
    </Layout>
  )
}
