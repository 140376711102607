import React, { useCallback, useMemo } from 'react'
import { GridColumns } from '@visx/grid'
import { curveLinear } from '@visx/curve'
import { Group } from '@visx/group'
import { scaleLinear, scalePoint, scaleOrdinal } from '@visx/scale'
import { LinePath } from '@visx/shape'
import { max } from 'd3-array'
import { format } from 'd3-format'
import { schemeTableau10 as schemeSet } from 'd3-scale-chromatic'
import 'twin.macro'

import OverviewAxes from '../OverviewAxes'
import OverviewTooltipContent from '../OverviewTooltipContent'
import Legend from '../Legend'
import OverviewChartTitle from '../OverviewChartTitle'
import useSVGTooltip from '../../connectors/useSvgTooltip'

const defaultMargin = {
  top: 30,
  left: 70,
  right: 40,
  bottom: 50,
}

const circleRadius = 4
const heightOffset = 20
const totalKey = 'Total'

const getX = (d) => d.date
const getY = (d) => d.emission

export default function FossilEnergyConsumptionChart({
  width,
  height,
  margin = defaultMargin,
  data,
  unit,
  xProp,
  zProp,
  toggleEra,
  toggle,
  tickFormat,
  tooltipFormat,
  eraByYear,
  ...rest
}) {
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const getZ = useCallback((d) => d[zProp], [zProp])

  const chartData = useMemo(() => {
    const emissionData = Object.values(
      data.reduce((acc, curr) => {
        const { date, emission } = curr
        const z = getZ(curr)

        const totalByZ = acc[date]?.[z] ? acc[date][z] + emission : emission
        const totalY = acc[date]?.emission
          ? acc[date].emission + emission
          : emission

        return {
          ...acc,
          [date]: {
            ...acc[date],
            date,
            [z]: totalByZ,
            emission: totalY,
          },
        }
      }, {})
    )

    if (toggleEra) {
      const dataWithEra = emissionData.map((yearData) => {
        const year = yearData[xProp]
        const yearItems = Object.entries(yearData)
          .filter(([key]) => key !== xProp)
          .map(([key, value]) => [key, value / eraByYear(year)])
        return {
          [xProp]: year,
          ...Object.fromEntries(yearItems),
        }
      })
      return dataWithEra
    }

    return emissionData
  }, [data, xProp, getZ, toggleEra, eraByYear])

  const xScale = scalePoint({
    domain: chartData.map(getX).sort(),
    range: [0, xMax],
  })

  const yScale = scaleLinear({
    domain: [0, max(chartData, getY)],
    range: [yMax, 0],
    nice: true,
  })

  const keys = [totalKey, ...new Set(data.map(getZ))]
  const colorScale = scaleOrdinal({
    domain: keys,
    range: schemeSet,
  })

  const style = { width }

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSVGTooltip()

  const handleTooltip = useCallback(
    (e, { label, value }) => {
      handlePointer(e, {
        label,
        value: format(tooltipFormat)(value),
        unit,
      })
    },
    [handlePointer, unit, tooltipFormat]
  )

  return (
    <div style={style} tw="relative" {...rest}>
      {toggle}
      <svg width={width} height={height - heightOffset} ref={containerRef}>
        <Group top={margin.top} left={margin.left}>
          <GridColumns
            scale={xScale}
            width={xMax}
            height={yMax}
            tw="fill-current text-grey-5"
          />
          <OverviewAxes
            xScale={xScale}
            yScale={yScale}
            top={yMax}
            leftNumTicks={yScale.ticks().length / 2}
            leftTickFormat={format(tickFormat)}
          />
          {keys.map((key, i) => {
            const chartKey = key === totalKey ? 'emission' : key
            return (
              <Group key={`line-${i}`}>
                <LinePath
                  curve={curveLinear}
                  data={chartData}
                  x={(d) => xScale(getX(d)) ?? 0}
                  y={(d) => yScale(d[chartKey]) ?? 0}
                  stroke={colorScale(key)}
                  shapeRendering="geometricPrecision"
                />
                {chartData.map((d, j) => {
                  return (
                    <circle
                      key={`circle-${i}-${j}`}
                      stroke="white"
                      strokeWidth="2"
                      fill={colorScale(key)}
                      r={circleRadius}
                      cx={xScale(getX(d))}
                      cy={yScale(d[chartKey])}
                      onPointerEnter={(e) =>
                        handleTooltip(e, { label: key, value: d[chartKey] })
                      }
                      onPointerLeave={hideTooltip}
                    />
                  )
                })}
              </Group>
            )
          })}
          <OverviewChartTitle height={yMax} unit={unit} />
        </Group>

        {tooltipOpen && (
          <>
            <TooltipInPortal
              left={tooltipLeft}
              top={tooltipTop}
              style={tooltipStyles}
            >
              <OverviewTooltipContent tooltipData={tooltipData} />
            </TooltipInPortal>
          </>
        )}
      </svg>

      <Legend scale={colorScale} />
    </div>
  )
}
